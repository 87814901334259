<div class="dark-body-only" id="outer-container">
    <!-- page-wrapper Start-->
    <div class="page-wrapper" [ngClass]="customizer.data.settings.sidebar.type" id="canvas-bookmark">
      <app-header-web-page (rightSidebarEvent)="rightSidebar($event)"></app-header-web-page>
      <!-- Page Body Start-->
      <div class="page-body-wrapper" [ngClass]="customizer.data.settings.sidebar.body_type">
       
        <div class="page-body">
          <main >
            <router-outlet #o="outlet"></router-outlet>
          </main>
        </div>
        <!-- footer start-->
        <footer class="footer">
          <app-footer></app-footer>
        </footer>
        <!-- footer End-->
      </div>
      <!-- Page Body End-->
    </div>
    <!-- page-wrapper End-->
  </div>