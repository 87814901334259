<!-- Page Header Start-->
<div class="container-fluid bg-dark px-5 d-none d-lg-block">
  <div class="row gx-0">
      <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
          <div class="d-inline-flex align-items-center" style="height: 45px;">
              <small class="me-3 text-light"><i class="fa fa-map-marker-alt me-2"></i> Bogotá D.C, COLOMBIA</small>
              <small class="me-3 text-light"><i class="fa fa-phone-alt me-2"></i>+601 311 503 4703</small>
              <small class="text-light"><i class="fa fa-envelope-open me-2"></i>contacto@sitricpos.com</small>
          </div>
      </div>
      <div class="col-lg-4 text-center text-lg-end">
          <div class="d-inline-flex align-items-center" style="height: 45px;">
              <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fa fa-twitter fw-normal"></i></a>
              <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fa fa-facebook fw-normal"></i></a>
              <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fa fa-linkedin-in fw-normal"></i></a>
              <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fa fa-instagram fw-normal"></i></a>
              <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle" href=""><i class="fa fa-youtube fw-normal"></i></a>
          </div>
      </div>
  </div>
</div>

<div class="container-fluid position-relative p-0">
  <nav class="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
      <div class="logo-wrapper">
        <a href="javascript:void(0)"><img src="assets/images/endless-logo.png" alt="" /></a>
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span class="fa fa-bars"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="navbar-nav ms-auto py-0">
              <a href="" class="nav-item nav-link active">Inicio</a>
              <!--<a href="" class="nav-item nav-link">Servicios</a>
              <div class="nav-item dropdown">
                  <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                  <div class="dropdown-menu m-0">
                      <a href="price.html" class="dropdown-item">Pricing Plan</a>
                      <a href="feature.html" class="dropdown-item">Our features</a>
                      <a href="team.html" class="dropdown-item">Team Members</a>
                      <a href="testimonial.html" class="dropdown-item">Testimonial</a>
                      <a href="quote.html" class="dropdown-item">Free Quote</a>
                  </div>
              </div>
              <a href="contact.html" class="nav-item nav-link">Contact</a>-->
          </div>
          <button type="button" class="btn text-primary ms-3" data-bs-toggle="modal" data-bs-target="#searchModal"><i class="fa fa-search"></i></button>
          <a routerLink="/full/authentication/login" class="btn btn-primary py-2 px-4 ms-3">Iniciar Sesión</a>
      </div>
  </nav>

  <div id="header-carousel" class="carousel slide carousel-fade" data-bs-ride="carousel">
      <div class="carousel-inner">
          <div class="carousel-item active">
              <img class="w-100" src="assets/images/carousel-1.jpg" alt="Image">
              <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                  <div class="p-3" style="max-width: 900px;">
                      <h5 class="text-white text-uppercase mb-3 animated slideInDown">Stric Pos esta cambiando</h5>
                      <h1 class="display-1 text-white mb-md-4 animated zoomIn">Espera nuevas noticias!!!</h1>
                      <!--<a href="quote.html" class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a>
                      <a href="" class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contact Us</a>-->
                  </div>
              </div>
          </div>
          <div class="carousel-item">
              <img class="w-100" src="assets/images/carousel-2.jpg" alt="Image">
              <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                  <div class="p-3" style="max-width: 900px;">
                      <h5 class="text-white text-uppercase mb-3 animated slideInDown">Creative & Innovative</h5>
                      <h1 class="display-1 text-white mb-md-4 animated zoomIn">Creative & Innovative Digital Solution</h1>
                      <a href="quote.html" class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a>
                      <a href="" class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contact Us</a>
                  </div>
              </div>
          </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
          data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
          data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
      </button>
  </div>
</div>

<!-- Full Screen Search Start -->
<div class="modal fade" id="searchModal" tabindex="-1">
  <div class="modal-dialog modal-fullscreen">
      <div class="modal-content" style="background: rgba(9, 30, 62, .7);">
          <div class="modal-header border-0">
              <button type="button" class="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body d-flex align-items-center justify-content-center">
              <div class="input-group" style="max-width: 600px;">
                  <input type="text" class="form-control bg-transparent border-primary p-3" placeholder="Type search keyword">
                  <button class="btn btn-primary px-4"><i class="bi bi-search"></i></button>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- Full Screen Search End -->

